<template>
  <div class="flex flex-wrap">


    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm"
      >Quantity</label>

      <input class="w-full bge-input bge-input-spacing rounded" v-model="quantity"
             type="text"/>
    </div>

    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm"
      >Boston Code / Product Description</label
      >
      <div class="flex w-full"> <!-- Adjusted the container width -->
        <input
            v-model="productSearchTermInput"
            type="text"
            class="flex-grow rounded-l bge-input bge-input-spacing"
            placeholder="Search"
            @keydown.enter="triggerProductSearch"
        />
        <button
            type="button"
            @click="triggerProductSearch"
            class="rounded-r bg-blue-200 hover:bg-blue-300 border border-blue-400 hover:border-blue-500 p-2 hover:shadow active:shadow-inner transition-colors duration-300 ease-in-out"
        >
          <Icon iconType="search"/>
        </button>
      </div>
    </div>
    <div class="w-full py-2">
      <Products :searchTerm="productSearchTerm" @productSelected="handleProductSelected"></Products>
    </div>

    <div class="bg-gray-100 p-4 rounded text-sm text-secondary">
      <p class="font-semibold ">NOTE:</p>
      <p>USE THE FOLLOWING CODES FOR ORDERING NON-DATABASE ITEMS AND EDIT THE ITEM DESCRIPTION AS REQUIRED:</p>
      <ul class="list-none pl-4">
        <li><span class="font-semibold">SPC1</span> - GENERAL SPARE PARTS ORDER</li>
        <li><span class="font-bold">SPC2</span> - GENERAL EQUIPMENT ORDER</li>
        <li><span class="font-bold">SPC3</span> - GENERAL OFFICE SUPPLIES ORDER</li>
        <li><span class="font-bold">SPC4</span> - MISCELLANEOUS ITEMS</li>
      </ul>
    </div>

  </div>
</template>

<script>
import Notification from "@/components/Notification.vue";
import notificationMixin from "@/mixins/notificationMixin";
import Icon from "@/components/Icon.vue";
import Products from "@/views/HR/Purchases/Components/Products.vue";


export default {
  name: "FindEquipment",
  mixins: [notificationMixin],
  components: {
    Products,
    Icon,

  },
  data() {
    return {
      quantity: 0,
      productSearchTermInput: '',
      productSearchTerm: '',
      tax_codes: [],
    };
  },
  async mounted() {
    await this.fetchDataMasterTaxCodes();
  },
  methods: {

    triggerProductSearch() {
      this.productSearchTerm = this.productSearchTermInput;
    },
    handleProductSelected(product) {

      let defaultTaxCode = null;
      let defaultTaxRate = null;

      if (this.tax_codes.length > 0) {
        defaultTaxCode = this.tax_codes[0].name;
        defaultTaxRate = this.tax_codes[0].value;
      }


      const tax_code = (product.metadata && product.metadata.tax_code !== undefined) ? product.metadata.tax_code : defaultTaxCode;


      let tax_rate = defaultTaxRate;
      const tax = this.tax_codes.find(t => t.name === tax_code);
      if (tax && tax.value !== undefined) {
        tax_rate = parseFloat(tax.value);
      }

      const productWithQuantity = {
        ...product,
        quantity: this.quantity,
        description: (product && product.name) || '',
        supplier_product_code: (product && product.supplier_info && product.supplier_info.part_no) || '',
        tax_code: tax_code || 'default_tax_code',
        tax_rate: tax_rate || 0,
        tax_codes: this.tax_codes || [],
      };


      this.$emit("complete", productWithQuantity);
    },

    async fetchDataMasterTaxCodes() {
      try {
        const response = await this.DatamasterService.getDataMasters('?noPaging=true&type=PO_Tax_Code&is_active=true&orderBy=name&order=1');
        this.tax_codes = response.map(item => ({
          data_master_id: item.data_master_id,
          name: item.name,
          value: item.value || '',
        }));
      } catch (error) {
        console.error('Error fetching data master certificates:', error);
      }
    },

  },
};
</script>
